Vue.component('indicatorComponent',{
    props:{
        indicator:{
            type:Object,
            required:true
        },
    },
    data:function(){
        return {
            isLoading:true,
            params:{},
            report:null
        }
    },
    computed:{
        reportTables:function () {
            if(this.report)
                return Object.keys(this.report);
            return [];
        }
    },
    methods:{
        getReportName(){
            return this.indicator.fields.ReportName;
        },
        runReport: function(parameters){
            let self = this;
            if(!parameters)
                parameters={}
            Object.assign(parameters,{run_kpi:true,kpiView:self.indicator.fields.kpiDetail});
            //console.log(parameters,this.getReportName(),self.indicator.fields.ReportName,);
            //return;
            return new Promise((resolve, reject) => {
                self.isLoading = true;
                self.report = null;
                $(".card-reveal").attr('style','');
                axios.post('/ecommerce/api/runReport', {
                    reportName: self.getReportName(),
                    params: parameters,
                })
                    .then(function (response) {
                        self.report = Object.freeze(response.data.report);
                        self.isLoading = false;

                        resolve(true);
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.isLoading = false;
                        $(".card-reveal").attr('style','');
                        reject(false)
                    });
            });
        },
    },
    template:`<div class="indicatorCard col s12 m6" :ref="'indicatorContainer-'+getReportName()">
                 <div class="card large">
                     <div class="card-content">
                          <span class="card-title activator grey-text text-darken-4 row">
                            <p class="col s12">
                                {{tr(indicator.fields.kpiDetail)}}
                            </p>    
                          </span>
                          <slot name="parameters" :runAction="runReport" :params="params" :loading="isLoading"></slot> 
                          <template v-if="isLoading" >
                             <div class="col s12 container center section white" style="min-height: 60%;" >
                                <div class="preloader-wrapper big active">
                                      <div class="spinner-layer spinner-blue">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                
                                      <div class="spinner-layer spinner-red">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                
                                      <div class="spinner-layer spinner-yellow">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                
                                      <div class="spinner-layer spinner-green">
                                        <div class="circle-clipper left">
                                          <div class="circle"></div>
                                        </div><div class="gap-patch">
                                          <div class="circle"></div>
                                        </div><div class="circle-clipper right">
                                          <div class="circle"></div>
                                        </div>
                                      </div>
                                </div>
                                <h4>{{tr('Page loading please wait')}}</h4>
                             </div>
                          </template>
                          <template v-else>
                                 <div :key="'indicatorRenderContainer-'+getReportName()" id="genericIndicatorView" :ref="'reportRenderContainer-'+getReportName()" class="indicatorRenderContainer col s12" >
                                      <template v-if="reportTables.length>0" v-for="table in reportTables">
                                            <reportTableView :table="Object.freeze(report[table])" :key="getReportName()+table" :tablename="table"></reportTableView>
                                      </template>
                                 </div>           
                          </template>
                    </div>
                 </div>  
             </div>  `
})

Vue.component('genericIndicatorPage',{
    template:`<div>            
                  <resetPasswordComponent></resetPasswordComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <registerComponent></registerComponent>
                  <loginComponent></loginComponent>
                  <template v-if="$store.getters.getAppState=='ready'">
                    <div class="section white">
                        <div class="container">
                            <div class="row">
                                <div class="left sticky-component home-category-tree">
                                    <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                </div>
                                <div class="col m9 s12">
                                     <template v-for="indicator of $store.getters.getIndicators">
                                        <indicator-component :indicator="indicator">
                                            <template slot="parameters" scope="reportScope">
                                                <genericIndicatorForm :reportScope="reportScope" :reportName="indicator.fields.ReportName" ></genericIndicatorForm>
                                            </template>
                                        </indicator-component>  
                                     </template>
                                </div>
                                <div class="col m2 s12 sticky-component right">
                                    <userSectionComponent></userSectionComponent>
                                    <carComponent></carComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="container center section white" style="min-height: 60%;" >
                       <h3>{{tr('Page loading please wait')}}</h3>
                     </div>
                  </template>
                  <footerSectionComponent></footerSectionComponent>
              </div>`
})